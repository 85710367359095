import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Firebase, { FirebaseContext } from "./containers";
import AuthUserContext from "./containers/Session";
import queryString from "query-string";
import EmbedModeContext from "./containers/EmbedModeContext";
import {
  LoginFormContext,
  LoginFormProvider,
} from "./containers/LoginFormContext";
import LogRocket from "logrocket";
import environment from "./environment";
import "./scss/base/fonts.scss";

require("dotenv").config();

if (environment.name === "production") {
  LogRocket.init("fansaves/fansaves");
}

class Index extends React.Component {
  static contextType = LoginFormContext;
  constructor(props) {
    super(props);
    this.firebase = new Firebase();
  }
  state = {
    checkingLoggedInStatus: true,
    showPopup: !!localStorage.getItem("showPopup"),
  };

  checkLocation = () => {
    const { pathname } = window.location;
    const parsedQuery = queryString.parse(window.location.search);
    const { embedMode } = parsedQuery;
    if (
      embedMode ||
      pathname === "/" ||
      pathname === "/how-it-works" ||
      pathname === "/unlock-exclusives" ||
      pathname === "/privacy-policy" ||
      pathname === "/terms-and-conditions" ||
      pathname.includes("/email-francais")
    ) {
      return false;
    } else {
      return true;
    }
  };

  componentDidMount() {
    if (this.checkLocation() && this.state.showPopup) {
      window.scrollTo(0, 0);
    }
    this.listener = this.firebase.auth.onAuthStateChanged(
      (firebaseAuthUser) => {
        if (firebaseAuthUser) {
          localStorage.removeItem("showPopup");
          this.setState({ showPopup: false });
          /* Now that we're logged in via Firebase.auth, grab user record */
          const userPath = "user/" + firebaseAuthUser.uid;
          const userRef = this.firebase.database.ref(userPath);

          if (environment.name === "production") {
            LogRocket.identify(firebaseAuthUser.uid, {
              email: firebaseAuthUser.email,
            });
          }

          userRef.on(
            "value",
            (snapshot) => {
              let authUser = snapshot.val();

              /* User has not been created in database yet. Will get created once first follow/redeem */
              if (authUser == null) {
                authUser = {};
              }

              authUser.key = snapshot.key;
              authUser.email = firebaseAuthUser.email;
              authUser.onboarding =
                !authUser.gender && !authUser.dob && !authUser.zip;

              this.setState({
                authUser: authUser,
                checkingLoggedInStatus: false,
              });
            },
            (error) => {
              console.log("...error loading user data.");
              console.log(error);
            }
          );
        } else {
          /* If logging off, remove listeners */
          if (this.state.authUser) {
            const userPath = "user/" + this.state.authUser.key;
            const userRef = this.firebase.database.ref(userPath);
            userRef.off();

            setTimeout(() => {
              this.setState({ showPopup: true });
              localStorage.setItem("showPopup", true);
            }, 45000);
          }

          this.setState({ authUser: null, checkingLoggedInStatus: false });
        }
      },
      () => {
        this.setState({ authUser: null });
      }
    );

    this.timer = setInterval(() => {
      this.checkAuthUserStatus();
    }, 45000);
  }

  componentDidUpdate(prevState) {
    const { showPopup, authUser } = this.state;
    if (prevState.showPopup !== this.state.showPopup) {
      if (showPopup && !authUser && this.checkLocation()) {
        window.scrollTo(0, 0);
      }
    }
  }

  componentWillUnmount() {
    this.listener();
    clearInterval(this.timer);
    localStorage.removeItem("showPopup");
  }

  checkAuthUserStatus = () => {
    const { authUser } = this.state;
    if (authUser) {
      localStorage.removeItem("showPopup");
      this.setState({ showPopup: false });
    } else {
      localStorage.setItem("showPopup", true);
      window.scrollTo(0, 0);
      this.setState({ showPopup: true });
    }
  };

  handleSuccessfulLogin = () => {
    if (this.state.authUser) {
      this.setState({ showPopup: false });
      localStorage.removeItem("showPopup");
    } else {
      this.checkAuthUserStatus();
    }
    window.sessionStorage.setItem(
      "redirect_path_after_onboarding",
      window.location.pathname
    );
  };

  render() {
    const { authUser, checkingLoggedInStatus } = this.state;

    const parsed = queryString.parse(window.location.search);
    let embedMode;

    if (parsed.embedMode) {
      embedMode = true;
    } else {
      embedMode = false;
    }

    return checkingLoggedInStatus ? (
      <div className="initial-loading"></div>
    ) : (
      <FirebaseContext.Provider value={this.firebase}>
        <AuthUserContext.Provider value={authUser}>
          <EmbedModeContext.Provider value={embedMode}>
            <LoginFormProvider>
              <App />
            </LoginFormProvider>
          </EmbedModeContext.Provider>
        </AuthUserContext.Provider>
      </FirebaseContext.Provider>
    );
  }
}

ReactDOM.render(<Index />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
