import React from "react";
import { Icon } from "@iconify/react";
import "./DashboardHeader.scss";
import { withRouter } from "react-router-dom";
import "../../../scss/base/typography.scss";
import { Link } from "react-router-dom";
import { DashboardButton, FlexContainer } from "../../../assets/fansaves-ui";

import "./DashboardHeader.scss";

const DashboardHeader = ({
  logo,
  name,
  email,
  description = "",
  profileUrl,
  follows = null,
  className,
  hideEl,
  followsUrl,
  exlusiveCodesUrl,
  exclusiveCodes,
  history,
  secondaryHeader,
  followersTotal,
}) => {
  return (
    <FlexContainer
      className={`component-dashboard-header ${className ? className : ""}`}
      margin="30px auto"
      direction={`${!secondaryHeader ? "horizontal" : "vertical"}`}
      alignItems={`${!secondaryHeader ? "flex-start" : "center"}`}
      gap="30px"
      width={`${secondaryHeader && "30%"}`}
      responsive
    >
      <img
        src={logo}
        alt={name}
        className={`dashboard-header-logo ${secondaryHeader && "small"}`}
      />

      <FlexContainer
        className="dashboard-header-title"
        width="100%"
        alignItems={`${!secondaryHeader ? "flex-start" : "center"}`}
        gap="10px"
      >
        <div
          className={`rubik-h4 ${secondaryHeader && "inter-b1"}`}
          data-notranslate
        >
          {name}
        </div>
        <div className="inter-b1">{email}</div>
        {/* <div className="inter-b1">{description}</div> */}
        <FlexContainer
          className="dashboard-header-team-buttons"
          gap="30px"
          direction="horizontal"
          justifyContent="flex-start"
          width="100%"
        >
          {followsUrl && (
            <DashboardButton
              className="follow-button"
              title={`Page Follows: ${follows || follows === 0 ? follows : ""}`}
              type="shadow-green"
              typography="inter-b1"
              onClick={(e) => {
                e.preventDefault();
                history.push(followsUrl);
              }}
              size="medium"
              noTranslate={true}
            />
          )}
          {exlusiveCodesUrl && (
            <DashboardButton
              className="exclusive-codes-button"
              // title={`Special Offers Codes: ${
              //   exclusiveCodes || exclusiveCodes === 0 ? exclusiveCodes : ""
              // }`}
              title="Special Offers Codes"
              type="shadow-white"
              typography="inter-b1"
              onClick={(e) => {
                e.preventDefault();
                history.push(exlusiveCodesUrl);
              }}
              size="medium"
            />
          )}
          {followersTotal && (
            <div className="followers-total inter-b1">
              Total Followers: {followersTotal}
            </div>
          )}
        </FlexContainer>
      </FlexContainer>

      {!hideEl && (
        <div className="dashboard-header-links">
          <div className="link-container">
            <Icon icon="fa-solid:user-alt" className="user-logo-icon" />
            <Link to={profileUrl} className="rubik-h3 ">
              Profile
            </Link>
          </div>
        </div>
      )}
    </FlexContainer>
  );
};

export default withRouter(DashboardHeader);
